import React, { ChangeEvent, ReactElement, useContext, useEffect, useRef, useState } from 'react';
import { SearchSummary } from 'lib/searchspring';
import { useCallback } from 'react';
import axios from 'axios';
import { Close, Search as SearchIcon, Backspace } from '@material-ui/icons';
import Link from '../Link';
import Autocomplete, { AutocompleteInputChangeReason } from '@material-ui/lab/Autocomplete';
import { InputAdornment, TextField, makeStyles, Popover } from '@material-ui/core';
import SiteDataContext from 'context/SiteDataContext';
import SearchProductCard from './SearchProductCard';
import { IPageFields } from 'types/contentful';
import { EntryCollection } from 'contentful';

interface Suggestion {
  query: string;
  correction: string | null;
  autocomplete: string[];
}

interface SearchResults {
  products: SearchSummary | undefined;
  content: EntryCollection<IPageFields>;
}

const autocompleteStyles = makeStyles({
  popper: {
    top: '8px !important',
  },
});

const inputStyles = makeStyles({
  underline: {
    '&:before': {
      borderBottom: 'none',
    },
    '&:after': {
      borderBottom: 'none',
    },
    '&:hover': {
      '&:not(.Mui-disabled)': {
        '&:before': {
          borderBottom: 'none',
        },
      },
    },
  },
});

const popoverStyles = makeStyles({
  root: {
    width: '100%',
  },
  paper: (isMobile) =>
    isMobile
      ? {
          inset: '0 !important',
          maxWidth: '100% !important',
          maxHeight: '100% !important',
          overflow: 'hidden !important',
        }
      : {
          width: '100%',
          height: 'calc(100vh - 84px)',
          left: '16px !important',
          top: '120px !important',
        },
});

const RESULTS_COUNT = 3;

export default function Search({ onClick }: { onClick?: () => void }): ReactElement {
  const { ecommIds } = useContext(SiteDataContext);
  const isMobile = window.innerWidth < 1024;
  const [autocompleteOpen, setAutocompleteOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState<SearchResults | null>(null);
  const [suggestions, setSuggestions] = useState<Suggestion | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [query, setQuery] = useState<string | null>(null);
  const debounceSearch = useRef<null | NodeJS.Timeout>(null);
  const debounceSuggest = useRef<null | NodeJS.Timeout>(null);
  const autocompleteClasses = autocompleteStyles();
  const inputClasses = inputStyles();
  const popoverClasses = popoverStyles(isMobile);
  const open = Boolean(anchorEl);
  const ssSiteIdString = ecommIds ? `&siteId=${ecommIds.fields.searchSpringId}` : '';

  const fetchSuggestions = useCallback(async () => {
    if (debounceSuggest.current !== null) clearTimeout(debounceSuggest.current);

    setLoading(false);
    debounceSuggest.current = setTimeout(async () => {
      setLoading(true);
      const { data } = await axios.get<Suggestion>(
        `/api/search-suggest?query=${query}${ssSiteIdString}`
      );
      setSuggestions(data);
      setLoading(false);
    }, 200);
  }, [query, ssSiteIdString]);

  const fetchFrames = useCallback(() => {
    if (query && query?.length >= 2) {
      if (debounceSearch.current !== null) clearTimeout(debounceSearch.current);

      debounceSearch.current = setTimeout(async () => {
        const { data } = await axios.get<SearchResults>(
          `/api/search-autocomplete?q=${query}${ssSiteIdString}`
        );
        if (window.dataLayer) {
          window.dataLayer.push({
            event: 'search_content_autocomplete',
            customSearchInput: query,
            customResultsSize: data.content.items.length,
          });
        }
        setResults(data);
      }, 300);
    }
  }, [query, ssSiteIdString]);

  // get suggestions when user types
  useEffect(() => {
    if (query) {
      fetchSuggestions();
      fetchFrames();
    } else {
      setSuggestions(null);
    }
  }, [fetchFrames, fetchSuggestions, query]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    // clear everything out
    setSuggestions(null);
    setQuery(null);
    setResults(null);

    // Close mobile menu
    if (onClick) onClick();

    // close popover
    setAnchorEl(null);
  };

  const handleChange = (
    e: ChangeEvent<unknown>,
    value: string,
    reason: AutocompleteInputChangeReason
  ): void => {
    switch (reason) {
      case 'clear':
        setQuery(null);
        setResults(null);
        break;
      case 'input':
        setQuery(value);
        break;
      default:
        setQuery(value);
    }
  };

  return (
    <>
      <button
        onClick={handleClick}
        id="search"
        aria-label="search"
        className="lg:self-center flex items-center rounded-full w-full lg:w-auto px-6 py-2 lg:p-0 -mx-6 -mb-1 lg:m-0 bg-neutral-alt lg:bg-white "
      >
        <span className="mr-1">Search</span>
        <SearchIcon
          fontSize={isMobile ? 'small' : 'default'}
          style={{ fill: 'rgb(var(--tertiary))' }}
        />
      </button>

      <Popover
        aria-labelledby="search"
        open={open}
        elevation={2}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: isMobile ? 'center' : 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: isMobile ? 'center' : 'right',
        }}
        classes={popoverClasses}
        className="max:container max-w-screen-max"
      >
        <div className="h-full flex flex-col justify-between">
          <div>
            <div className="flex px-4 pt-4 md:p-6 items-center border-b md:border-none">
              <div className="md:border-b w-full md:px-10 pb-2 flex">
                <Autocomplete
                  open={autocompleteOpen}
                  loading={loading}
                  classes={autocompleteClasses}
                  options={suggestions?.autocomplete || []}
                  freeSolo
                  fullWidth
                  autoComplete
                  clearOnEscape
                  blurOnSelect
                  closeIcon={<Backspace fontSize="small" />}
                  onOpen={() => setAutocompleteOpen(true)}
                  onClose={() => setAutocompleteOpen(false)}
                  onInputChange={handleChange}
                  forcePopupIcon={
                    suggestions && suggestions?.autocomplete?.length > 0 ? true : 'auto'
                  }
                  filterOptions={(options, params) => {
                    // Suggest the correction value
                    if (params.inputValue !== '' && suggestions?.correction) {
                      options.unshift(suggestions.correction);
                    }

                    return options;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      placeholder={
                        ecommIds
                          ? 'Search for specific frames, brands, page keywords'
                          : 'Search for page keywords'
                      }
                      InputProps={{
                        ...params.InputProps,
                        autoFocus: true,
                        autoComplete: 'new-password',
                        classes: inputClasses,
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
                <button onClick={handleClose} aria-label="close search" className="ml-5">
                  <Close />
                </button>
              </div>
            </div>

            {/* results heading */}
            {results && results.products && (
              <div
                className={`${
                  results ? 'flex' : 'hidden'
                } justify-between items-center m-4 text-sm md:text-lg`}
              >
                <div className="flex items-center text-primary-actual">
                  <h2 className="font-heading">Frames</h2>
                  <span className="ml-1">({results && results?.products.totalResults})</span>
                </div>

                {/* view all link */}
                {results && results?.products.totalResults > RESULTS_COUNT && (
                  <Link slug="/search" query={`q=${query}&t=0`}>
                    <a className="text-tertiary-actual">
                      <button onClick={handleClose}>VIEW ALL</button>
                    </a>
                  </Link>
                )}
              </div>
            )}
          </div>

          {query && query?.length > 1 && (
            <div className="overflow-y-scroll h-full">
              {results?.products &&
              results.products.results &&
              results.products.results.length > 0 ? (
                <>
                  {/* result cards */}
                  <ul
                    className="relative flex flex-col md:flex-row flex-wrap"
                    aria-label="results list"
                  >
                    {results.products.results.map(
                      (item, i) =>
                        i < RESULTS_COUNT && (
                          <SearchProductCard
                            handleClose={handleClose}
                            key={item.name + i}
                            {...item}
                          />
                        )
                    )}
                  </ul>
                </>
              ) : (
                <>
                  {/* no results response */}
                  {results?.products &&
                    results.products.results &&
                    results.products.results.length === 0 && (
                      <div className="text-sm md:text-xl mx-6 md:mx-12 mt-2 md:mt-4 flex-1">
                        Bummer. Doesn’t look like we carry a frame by that name.
                      </div>
                    )}
                </>
              )}

              {results?.content && (
                <div className="my-8">
                  {/* Content heading */}
                  <div
                    className={`${
                      results ? 'flex' : 'hidden'
                    } justify-between items-center m-4 text-sm md:text-lg`}
                  >
                    <h2 className="font-heading text-primary-actual">
                      Reading ({results.content.total} pages)
                    </h2>

                    {/* view all link */}
                    {results.content.total > RESULTS_COUNT && (
                      <Link slug="/search" query={`q=${query}&t=1`}>
                        <a className="text-tertiary-actual mt-4 block">
                          <button onClick={handleClose}>VIEW ALL</button>
                        </a>
                      </Link>
                    )}
                  </div>

                  {/* content links */}
                  {results.content.total > 0 && (
                    <ul className="mt-4 md:mt-8 mx-4" aria-label="content results list">
                      {results.content.items.map((item, i) => {
                        if (i < RESULTS_COUNT) {
                          return (
                            <li className="text-sm md:text-xl my-3" key={item.fields.slug}>
                              <Link slug={item.fields.slug}>
                                <button className="underline" onClick={handleClose}>
                                  {item.fields.title}
                                </button>
                              </Link>
                              <p className="text-xs md:text-base" style={{ color: '#828282' }}>
                                {item.fields.description}
                              </p>
                            </li>
                          );
                        }
                      })}
                    </ul>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </Popover>
    </>
  );
}
