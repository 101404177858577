import { ReactElement } from 'react';
import {
  IFragmentSocialMediaLinks,
  INavFooterFields,
  INavLink,
  INavSubNav,
} from 'types/contentful';
import { Facebook, Instagram, Twitter, Pinterest, LinkedIn, YouTube } from '@material-ui/icons';
import { SvgIconTypeMap } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';

import styles from './styles.module.css';
import RichText from '../../UI/Richtext';
import NavLink from 'components/UI/Nav/NavLink';
import Image from '../../UI/Image';
import Link from '../../UI/Link';

interface FooterProps extends INavFooterFields {
  socialMediaLink?: IFragmentSocialMediaLinks[] | null;
  currentSlug: string;
}

const Icons: { [index: string]: OverridableComponent<SvgIconTypeMap<unknown, 'svg'>> } = {
  Facebook,
  Instagram,
  Twitter,
  Pinterest,
  LinkedIn,
  YouTube,
};

const SocialIcon = ({ icon }: { icon: string }): ReactElement => {
  const Icon = Icons[icon];
  return <Icon />;
};

function isNavLinkArray(links: unknown): links is INavLink[] {
  return (
    Array.isArray(links) &&
    links.every(
      (link) =>
        typeof link === 'object' &&
        link !== null &&
        'fields' in link &&
        typeof link.fields === 'object' &&
        'slug' in link.fields &&
        typeof link.fields.slug === 'string' &&
        'text' in link.fields &&
        typeof link.fields.text === 'string' &&
        'openInNewTab' in link.fields &&
        typeof link.fields.openInNewTab === 'boolean'
    )
  );
}

export default function FooterNav({
  links,
  footerContent,
  socialMediaLink,
  footerType,
  footerLogo,
  currentSlug,
}: FooterProps): ReactElement {
  const defaultView = 'Global';
  const linkList =
    links &&
    isNavLinkArray(links) &&
    links.map(({ fields: { slug, text, openInNewTab } }, index) => {
      const textClassName = index % 2 === 0 ? 'right' : 'left';

      return (
        <li className={`text-${textClassName} md:justify-center px-5`} key={index}>
          <NavLink
            slug={slug}
            text={text ?? ''}
            openInNewTab={openInNewTab}
            currentSlug={currentSlug}
          />
        </li>
      );
    });

  const linkListRebrand =
    links &&
    isNavLinkArray(links) &&
    links.map(({ fields: { slug, text, openInNewTab } }, index) => {
      const borderClassName = links.length - 1 === index ? 'divide-r-0' : 'border-r';
      return (
        <span className={`px-3 ${borderClassName} ${styles.footer_rebrand_border_r}`} key={index}>
          <NavLink slug={slug} text={text} openInNewTab={openInNewTab} currentSlug={currentSlug} />
        </span>
      );
    });

  const columnLinks = links && !isNavLinkArray(links) ? links : null;

  return (
    <div>
      {(footerType === undefined || footerType === defaultView) && (
        <footer className="bg-primary text-white pt-10 pb-16 lg:pt-16 lg:pb-10 font-extralight flex-none">
          <div className="max-w-footer container mx-auto">
            <ul className="grid grid-cols-2 md:flex gap-x-10 flex-wrap gap-y-5 pb-5 text-xl mx-auto justify-center">
              {linkList}
            </ul>
            <div className={styles.footer_content_spacing}>
              {footerContent && (
                <RichText document={footerContent} classNames="text-sm mx-4 md:mx-24 text-center" />
              )}
            </div>
            <div className="mx-auto mt-4 text-center">
              {socialMediaLink?.map((icon) => (
                <a
                  key={icon.fields.socialMediaType}
                  href={icon.fields.socialMediaUrl}
                  className="mx-2"
                  target="_blank"
                  rel="noreferrer"
                >
                  <SocialIcon icon={icon.fields.socialMediaType} />
                </a>
              ))}
            </div>
          </div>
        </footer>
      )}
      {footerType !== defaultView && footerType === 'Column' && (
        <div>
          <footer className="bg-muted px-10 pt-10 pb-16 lg:pt-16 lg:pb-10 text-primary-actual font-extralight flex-none">
            <div className="lg:w-3/4 mx-auto">
              <div className="grid grid-cols-2 md:grid-cols-4 gap-10">
                <div className="grid grid-col-1 gap-10">
                  {(columnLinks as INavSubNav[])?.slice(0, 2).map((linkCat, index) => (
                    <div key={index}>
                      <h4 className="font-bold mb-2 uppercase">
                        <b>{linkCat.fields.text}</b>
                      </h4>
                      <ul>
                        {linkCat.fields.links.map((link) => (
                          <li className={`mb-2 `} key={link.fields.slug}>
                            <NavLink
                              slug={link.fields.slug}
                              text={link.fields.text}
                              openInNewTab={link.fields.openInNewTab}
                              currentSlug={currentSlug}
                            />
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>

                {(columnLinks as INavSubNav[])?.slice(2).map((linkCat, index) => (
                  <div key={index}>
                    <h4 className="font-bold mb-2 uppercase">
                      <b>{linkCat.fields.text}</b>
                    </h4>
                    <ul>
                      {linkCat.fields.links.map((link) => (
                        <li className="mb-2" key={link.fields.slug}>
                          <NavLink
                            slug={link.fields.slug}
                            text={link.fields.text}
                            openInNewTab={link.fields.openInNewTab}
                            currentSlug={currentSlug}
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
              <div className="flex flex-col md:flex-row space-y-6 md:space-y-0 justify-between items-center mx-auto mt-5">
                <div className="flex gap-4 ">
                  {socialMediaLink?.map((icon) => (
                    <a
                      key={icon.fields.socialMediaType}
                      href={icon.fields.socialMediaUrl}
                      target="_blank"
                      rel="noreferrer"
                      className="large-icon"
                    >
                      <SocialIcon icon={icon.fields.socialMediaType} />
                    </a>
                  ))}
                </div>
                {footerContent && <RichText document={footerContent} classNames="text-sm" />}
              </div>
            </div>
          </footer>
        </div>
      )}
      {footerType !== defaultView && footerType === 'Rebranding' && (
        <footer className="  pt-10 pb-16 lg:pt-16 lg:pb-10 font-extralight flex-none">
          {footerLogo && (
            <div className="cursor-pointer pb-10">
              <Link slug="home">
                <a aria-label="home" className="justify-center">
                  <Image
                    image={footerLogo}
                    query="h=60"
                    classNames="m-auto sm:max-w-300 max-w-200 max-h-40 sm:max-h-60"
                  />
                </a>
              </Link>
            </div>
          )}
          <div className="flex flex-wrap justify-center">
            <span className={`px-3 border-r ${styles.footer_rebrand_border_r}`}>
              {footerContent && <RichText document={footerContent} classNames="" />}
            </span>
            {linkListRebrand}
          </div>
        </footer>
      )}
    </div>
  );
}
