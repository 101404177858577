import { ReactElement } from 'react';
import RichText from './Richtext';
import { IFragmentBelowHeaderBannerFields } from 'types/contentful';

export default function PromotionalBanner({
  bannerText,
  disclaimer,
  colorScheme,
  openInNewTab,
}: IFragmentBelowHeaderBannerFields): ReactElement {
  return (
    <div className={`bg-${colorScheme.toLowerCase()} text-white px-2 py-3 ${openInNewTab}`}>
      {bannerText && (
        <RichText
          document={bannerText}
          classNames="text-center text-2xl md:text-4xl bold-highlight leading-tight"
        />
      )}
      <p className="text-center text-sm ">{disclaimer}</p>
    </div>
  );
}
